// ./src/App.js
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import BlocoInicial from "./components/BlocoInicial";
import CursoZML from "./components/CursoZML";
import SobreNos from "./components/SobreNos";
import Servicos from "./components/Servicos";
import Missao from "./components/Missao";
import Contato from "./components/Contato";
import backgroundImage from "./img/Fundo.jpg"; // Imagem de fundo para o bloco inicial
import logo from "./img/Logo.png"; // Logo da empresa
import FAQ from "./components/FAQ";

function App() {
  useEffect(() => {
    if (!window.fbq) {
      // Meta Pixel Code
      (function(f,b,e,v,n,t,s) {
        if(f.fbq) return;
        n=f.fbq=function(){n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)};
        if(!f._fbq) f._fbq=n;
        n.push=n; n.loaded=!0; n.version='2.0';
        n.queue=[]; t=b.createElement(e); t.async=!0;
        t.src=v;
        s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      
      window.fbq('init', '553982500958165');
      window.fbq('track', 'PageView');
    }

    // For noscript support
    const noscript = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = "1";
    img.width = "1";
    img.style.display = "none";
    img.src = "https://www.facebook.com/tr?id=553982500958165&ev=PageView&noscript=1";
    noscript.appendChild(img);
    document.body.appendChild(noscript);
  }, []); // O array vazio [] garante que o código execute uma vez após o primeiro render

  return (
    <div>
      <Helmet>
        <title>Carol Marques Leilões - Assessoria em Leilão Imobiliário</title>
        <meta
          name="description"
          content="Serviços de assessoria jurídica especializada em leilões imobiliários, oferecendo apoio completo desde a pesquisa até o pós-leilão."
        />
        <meta name="keywords" content="leilão de imóveis, assessoria jurídica, arrematação, segurança jurídica" />
        <meta name="author" content="Carol Marques Leilões" />
        <link rel="canonical" href="https://carolmarquesleiloes.com.br" />
        <meta property="og:title" content="Carol Marques Leilões - Assessoria Completa" />
        <meta property="og:description" content="Serviços de assessoria jurídica especializada em leilões imobiliários." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://carolmarquesleiloes.com.br" />
        <meta property="og:image" content="https://carolmarquesleiloes.com.br/img/Logo.png" />
        <meta property="og:see_also" content="https://www.instagram.com/carolmarquesleilao" />
        <meta name="facebook-domain-verification" content="j0vd0ppcp35uzk440o55dpm9cesdoe" />
      </Helmet>

      <BlocoInicial backgroundImage={backgroundImage} logo={logo} />
      <CursoZML/>
      <Servicos />
      <SobreNos />
      <Missao />
      <FAQ />
      <Contato />
    </div>
  );
}

export default App;

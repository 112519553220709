import React from "react";

export default function Curso() {
    return (
        <section className="py-16 px-8 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Curso Zero Ao Milhão Com Leilão
            </h2>
            <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-6">
                Descubra como arrematar imóveis pela metade do preço através do leilão e lucrar começando com pouco
                ou com zero reais, assessorando investidores.
            </p>
            <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-8">
                Entenda o método Martelada do Thor, que traz o passo a passo seguro para arrematar imóveis de leilão pela
                metade do preço e transformar em lucro, sem precisar de muito dinheiro para começar e ainda como
                assessorar investidores lucrando alto.
            </p>
            
            {/* Benefícios do curso */}
            <div className="max-w-2xl mx-auto text-left bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-[#C28840] mb-4 text-center">O que você terá acesso:</h3>
                <ul className="text-gray-700 space-y-3">
                    <li className="flex items-center"><span className="text-green-600 text-xl mr-2">✓</span> Acesso imediato ao curso completo</li>
                    <li className="flex items-center"><span className="text-green-600 text-xl mr-2">✓</span> Suporte individual direto comigo e minha equipe em grupo de WhatsApp por 90 dias</li>
                    <li className="flex items-center"><span className="text-green-600 text-xl mr-2">✓</span> Reuniões mensais online para tirar dúvidas comigo por 1 ano</li>
                </ul>
            </div>
            
            {/* Chamada para ação */}
            <div className="mt-8">
                <p className="text-lg text-gray-800 font-semibold">Saiba mais clicando aqui:</p>
                <a href="https://zeroaomilhaocomleilao.hotmart.host/zml" 
                   className="mt-4 inline-block bg-[#C28840] text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:bg-[#a57033] transition">
                    Zero Ao Milhão Com Leilão
                </a>
            </div>
        </section>
    );
}
